.empty-events-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    min-height: 500px;

}

.empty-events-message {
    font-size: 1.3rem;
    font-family: object_sans_regular;
    color: var(--darkest-grey);
}

.event-viewer {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 1rem;
    font-size: 2.15rem;
}

.browser-loading-container {
    background-color: var(--light-grey);
    min-height: 225px;
    border-radius: 1em;
}

@media screen and (max-width: 1250px){
    .event-viewer {
        grid-template-columns: auto;
    }
}