.event-spread {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: column;
    overflow-x: scroll;
    gap: 1em;
    padding-bottom: 1em;

    width: fit-content;
    max-width: 100%;

    border-radius: 1em;

    font-size: 1.9rem;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.event-spread {
    width: 100%;
    scrollbar-width: auto;
    scrollbar-color: var(--pink);
}

/* Chrome, Edge, and Safari */
.event-spread::-webkit-scrollbar {
    width: 16px;
}

.event-spread::-webkit-scrollbar-track {
    background: var(--light-grey);
    border-radius: 10px;
}

.event-spread::-webkit-scrollbar-thumb {
    background-color: var(--pink);
    border-radius: 10px;
    border: 3px solid #ffffff;
}

@media screen and (max-width: 800px) {
    .event-spread {
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;
        gap: 0.6em;
    }
    .preview-override {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .spread-loading-container-large {
        grid-column-start: 1;
        grid-column-end: 3;

        min-height: 150px !important;  
        min-width: 0px !important;
    }
    .spread-loading-container-small {
        min-height: 150px !important;
        min-width: 0px !important;
    }
    .month-preview {
        font-size: 1.5rem !important;
        min-height: 100px !important;
    }
    
}

.month-preview {
    display: flex;
    justify-content: space-between;
    gap: 1em;
    min-height: 125px;

    background-color: var(--light-grey);
    border-radius: 1em;
    padding: 1em;
    font-size: 1.9rem;
}
.month-preview-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-wrap: wrap;
}
.month-preview-text h1 {
    font-family: object_sans_bold;
    font-size: 1em;
}
.month-preview-text p {
    font-size: 0.6em;
    margin-bottom: 0.2em;
}
.month-preview-decoration {
    align-self: flex-end;
    width: 1.1em;
}

.spread-loading-container-large {
    background-color: var(--light-grey);
    border-radius: 1em;
    min-width: 500px;
    min-height: 250px;
}

.spread-loading-container-small {
    background-color: var(--light-grey);
    border-radius: 1em;
    min-width: 250px;
    min-height: 250px;
}
