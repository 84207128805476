.linker {
    width: fit-content;
    display: block;
}

.button {
    display: flex;
    gap: 0.5em;
    align-items: center;
    width: fit-content;

    padding: 0.5em 0.8em 0.4em 0.8em;

    border-radius: 0.5em;
    border-width: 0.075em !important;

    transition: backgroud-color 2s linear;
}

.button p {
    font-family: object_sans_regular;

    display: block;
    padding-top: 0.125em;
}

.bold {
    font-family: object_sans_bold !important;
}

.button-image {
    display: block;
    width: 1.25em;
}

.hoverOnly {
    border-style: none;
    background: none;
    background-color: none;
}
.hoverOnly:hover {
    background-color: var(--light-grey);
}

.disabled-link {
    pointer-events: none;
    cursor: default;
    opacity: 50%;
}