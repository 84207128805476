.detail-page-container {
    display: grid;
    grid-template-columns: auto 90fr;
    grid-template-rows: auto auto;
    gap: 1rem;
}

.back-button {
    width: 1.5rem;

    padding: 0.9em 1em 0.7em 1em;

    border-radius: 0.5em;
    border-width: 0.075em !important;

    transition: backgroud-color 2s linear;
    cursor: pointer;
}

.detail-page-title {
    font-family: object_sans_regular;
    font-size: 1.5rem;
    align-self: center;
    padding-top: 0.2rem;
}

.detail-emoji {
    justify-self: center;
    font-size: 3rem;
}
.emoji-loading {
    width: 3rem;
    height: 3rem;
    border-radius: 0.5em;
    justify-self: center;
}

.details-sub-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.detail-entry-title {
    font-family: object_sans_regular;
    font-size: 1rem;
    margin-bottom: 0.1rem;
}
.detail-entry-info {
    font-family: object_sans_regular;
    font-size: 1.5rem;
}
.first-entry {
    font-family: object_sans_bold !important;
    font-size: 2rem !important;
}
.detail-entry-desc {
    font-family: object_sans_regular;
    font-size: 1.2rem;
    line-height: 1.1em;
    margin-bottom: 1em;
}

.details-title-loading {
    border-radius: 0.2rem;
    height: 0.5rem;
    width: 6rem;
    margin-bottom: 0.15rem;
}
.details-loading1 {
    border-radius: 0.5rem;
    height: 2rem;
    width: 13rem;
}
.details-loading2 {
    border-radius: 0.5rem;
    height: 2rem;
    width: 20rem;
}
.details-loading3 {
    border-radius: 0.5rem;
    height: 2rem;
    width: 15rem;
}