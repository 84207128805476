.job-link-container {
    display: block;
    width: 100%;
    height: fit-content;
}

.executive-position-container {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    border-radius: 1em;
    gap: 0.5em;

    background-color: var(--light-grey);
    width: 100%;
    height: fit-content;
    align-items: start;
}

.job-title {
    font-family: object_sans_bold;
    text-wrap: wrap;
    margin-bottom: 0.1em;
}

@media screen and (max-width: 800px) {
    .job-title {
        font-size: 0.7em !important;
    }
    .executive-position-container {
        font-size: 0.7em !important;
    }
}