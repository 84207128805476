.join-container {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.join-inner-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    margin-bottom: 1rem;
}

.job-browser {
    overflow-y: scroll;
    max-height: 250px;
    border-radius: 0.3em;

    display: flex;
    gap: 0.8em;
    flex-direction: column;

    background-color: var(--less-light-grey);
    padding: 0.5em;
}

.job-browser-loading-container {
    background-color: var(--light-grey);
    min-height: 200px;
    border-radius: 0.9em;
}

@media screen and (min-height: 800px) {
    .join-container {
        width: 100%;
        height: 88vh;
    }
}

@media screen and (max-width: 800px) {
    .join-container {
        height: fit-content;
        width: 100%;
    
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .join-inner-container {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }

    .job-browser {
        border-radius: 0.3em;
        height: fit-content;
    
        display: flex;
        gap: 0.8em;
        flex-direction: column;
    }
}


