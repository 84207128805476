header {
    width: 100%;

    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    gap: 1em;

    padding: 2em 0px;
}

.nav-logo {
    width: 2.75em;
    padding: 0px 1em;
}

.nav-links {
    justify-self: end;

    display: flex;
    flex-wrap: nowrap;
    
    align-items: center;
    gap: 1em;
}

@media screen and (max-width: 800px) {
    header {
        width: 100%;
    
        position: relative;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        gap: 1em;
    
        padding: 2em 0px;
    }
    .nav-links {
        grid-column-start: 1;
        grid-column-end: 3;
        display: grid;
        width: 100%;
    
        align-items: center;
        gap: 0.75em;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
    .nav-hamburger {
        cursor: pointer;
        position: relative;
        top: 0px;
    }
    .nav-menu {
        justify-self: end;
        display: flex;
        align-items: center;
        gap: 1em;
    }
    .nav-logo {
        width: 2em;
        padding: 0px 1em;
        position: relative;
        top: 3.5px;
    }
}