.tagContainer {
    display: flex;
    align-items: center;
    gap: 0.3em;

    width: 100%;
}

.tag {
    display: block;
    font-family: object_sans_regular;
    border-radius: 2em;
    border-width: 0.1em;
    padding: 0.8em 0.9em 0.6em 0.9em;
}