.link-container {
    display: block;
    width: 100%;
    height: 100%;
}

.preview-container {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    border-radius: 1em;
    gap: 0.5em;

    background-color: var(--light-grey);
    width: 100%;
    height: 100%;
}

.preview-decoration {
    display: block;
    min-width: 1.1em;
}

.preview-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.8em;
    text-wrap: wrap;
}
.date-title {
    font-family: object_sans_regular;
    font-size: 0.8em;
    margin-top: 0.2em;
}
.event-title {
    font-family: object_sans_bold;
    text-wrap: wrap;
    margin-bottom: 0.1em;
    
}
.event-info {
    font-size: 0.5em !important;
}
